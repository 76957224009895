const appointmentRouter = {
  route: null,
  name: null,
  title: '教务管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/appointment', // 文件路径
  order: null,
  inNav: true,
  permission: ['训练预约', '接送班车'],
  children: [
    {
      title: '教学安排',
      type: 'view',
      name: null,
      route: '/teaching-arrangements/list',
      filePath: 'view/appointment/teaching/teaching-arrangements-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
    },
    {
      title: '教学预约详情',
      type: 'view',
      name: null,
      route: '/teaching-detail/list',
      filePath: 'view/appointment/teaching/teaching-detail-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
    },
    {
      title: '接送路线',
      type: 'view',
      name: null,
      route: '/transfer-route/list',
      filePath: 'view/appointment/transfer/stansfer-route-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
    },
    {
      title: '接送安排',
      type: 'view',
      name: null,
      route: '/transfer-arrangements/list',
      filePath: 'view/appointment/transfer/transfer-arrangements-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
    },
    {
      title: '接送预约详情',
      type: 'view',
      name: null,
      route: '/transfer-detail/list',
      filePath: 'view/appointment/transfer/transfer-detail-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
    },
    // {
    //   title: '训练预约',
    //   type: 'view',
    //   name: null,
    //   route: '/training-appointment/list',
    //   filePath: 'view/appointment/training/training-appointment-list.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-tushuguanli',
    //   permission: ['训练预约'],
    // },
    // {
    //   title: '接送班车',
    //   type: 'view',
    //   name: null,
    //   route: '/shuttle-appointment/list',
    //   filePath: 'view/appointment/shuttle/shuttle-appointment-list.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-tushuguanli',
    //   permission: ['接送班车'],
    // },
  ],
}

export default appointmentRouter
