const officeRouter = {
  route: null,
  name: null,
  title: '办公管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/office', // 文件路径
  order: null,
  inNav: true,
  permission: ['批文信息','行政部经理批文审批','行政部分管领导批文审批','业务部门经理批文审批',
  '业务部门分管领导批文审批','总经理批文审批','业务部门副经理批文传阅','副总经理批文传阅','档案管理',
  '公司列表','合同管理','部门列表','员工评价','员工档案','物品申购','物品申购审批','字典管理',
  '批文待办','批文附件','印章信息','用章申请','用章审批','用章流程','发文信息','发文审批','发文传阅','发文审批流',
  '离职审批','通讯录'],
  children: [
    {
      title: '公司列表',
      type: 'view',
      name: null,
      route: '/company/list',
      filePath: 'view/office/company/company-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['公司列表'],
    },
    {
      title: '部门列表',
      type: 'view',
      name: null,
      route: '/department/list',
      filePath: 'view/office/department/department-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['部门列表'],
    },
    {
      title: '员工档案',
      type: 'view',
      name: null,
      route: '/employee/list',
      filePath: 'view/office/employee/employee-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['员工档案'],
    },
    {
      title: '员工评价',
      type: 'view',
      name: null,
      route: '/evaluate/list',
      filePath: 'view/office/evaluate/employeeEvaluation-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['员工评价'],
    },
    {
      route: null,
      name: null,
      title: '公文管理',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/office',
      inNav: true,
      permission: ['公文查阅','批文信息','批文审批','批文传阅','批文审批流','批文待办','批文附件'],
      children: [
        {
          title: '公文信息',
          type: 'view',
          name: null,
          route: '/approval/list',
          filePath: 'view/office/approval/approval-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['批文信息'],
        },
        {
          title: '公文查阅',
          type: 'view',
          name: null,
          route: '/approval-consult/list',
          filePath: 'view/office/approval/approval-list-consult.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['公文查阅'],
        },
        {
          title: '审批列表',
          type: 'view',
          name: null,
          route: '/process-approval/list',
          filePath: 'view/office/approval/process-approval-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['批文审批'],
        },
        {
          title: '待办列表',
          type: 'view',
          name: null,
          route: '/approval-to-do/list',
          filePath: 'view/office/approval/approval-to-do-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['批文待办'],
        },
        {
          title: '传阅列表',
          type: 'view',
          name: null,
          route: '/approval-circulate/list',
          filePath: 'view/office/approval/approval-circulate-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['批文传阅'],
        },
        {
          title: '公文审批流',
          type: 'view',
          name: null,
          route: '/approval-process/list',
          filePath: 'view/office/approval-process/approval-process-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['批文审批流'],
        },
        {
          title: '公文附件',
          type: 'view',
          name: null,
          route: '/approval-enclosure/list',
          filePath: 'view/office/approval/approval-enclosure-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['批文附件'],
        },
        {
          title: '我的收藏',
          type: 'view',
          name: null,
          route: '/collection/list',
          filePath: 'view/office/approval/collection-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
        },
        {
          title: '常用语',
          type: 'view',
          name: null,
          route: '/approval-common-word/list',
          filePath: 'view/office/approval/approval-common-word/approval-common-word-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
        },
      ],
    },
    {
      route: null,
      name: null,
      title: '发文管理',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/office',
      inNav: true,
      permission: ['发文信息','发文审批','发文传阅','发文审批流'],
      children: [
        {
          title: '发文信息',
          type: 'view',
          name: null,
          route: '/article/list',
          filePath: 'view/office/article/article-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['发文信息']
        },
        {
          title: '审批列表',
          type: 'view',
          name: null,
          route: '/article/approval/list',
          filePath: 'view/office/article/article-approval-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['发文审批']
        },
        {
          title: '传阅列表',
          type: 'view',
          name: null,
          route: '/article/circulate/list',
          filePath: 'view/office/article/article-circulate-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['发文传阅']
        },
        {
          title: '发文审批流',
          type: 'view',
          name: null,
          route: '/article-process/list',
          filePath: 'view/office/article/article-process-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['发文审批流']
        },
      ]
    },
    {
      title: '合同管理',
      type: 'view',
      name: null,
      route: '/contract/list',
      filePath: 'view/office/contract/contract-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['合同管理'],
    },
    {
      title: '档案管理',
      type: 'view',
      name: null,
      route: '/archives/list',
      filePath: 'view/office/archives/archives-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['档案管理'],
    },
    {
      route: null,
      name: null,
      title: '物品申购管理',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/office',
      inNav: true,
      permission: ['物品申购','物品申购审批'],
      children: [
        {
          title: '申购信息',
          type: 'view',
          name: null,
          route: '/apply-purchase/list',
          filePath: 'view/office/apply-purchase/apply-purchase-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['物品申购'],
        },
        {
          title: '待审批',
          type: 'view',
          name: null,
          route: '/apply-purchase-audit/list',
          filePath: 'view/office/apply-purchase/apply-purchase-audit-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['物品申购审批'],
        },
      ],
    },
    {
      route: null,
      name: null,
      title: '用章管理',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/office',
      inNav: true,
      permission: ['印章信息','用章申请','用章审批','用章流程'],
      children: [
        {
          title: '用章流程',
          type: 'view',
          name: null,
          route: '/technological_process/list',
          filePath: 'view/office/technological-process/technological-process-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['用章流程'],
        },
        {
          title: '印章信息',
          type: 'view',
          name: null,
          route: '/seal-info/list',
          filePath: 'view/office/seal/seal-info/seal-info-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['印章信息'],
        },
        {
          title: '用章信息',
          type: 'view',
          name: null,
          route: '/seal-user-info/Alllist',
          filePath: 'view/office/seal/seal-user/seal-all-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['用章信息'],
        },
        {
          title: '用章申请',
          type: 'view',
          name: null,
          route: '/seal-user-info/list',
          filePath: 'view/office/seal/seal-user/seal-user-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['用章申请'],
        },
        {
          title: '用章审批',
          type: 'view',
          name: null,
          route: '/seal-user-approval/list',
          filePath: 'view/office/seal/seal-user/seal-user-approval-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['用章审批'],
        },
      ],
    },
    {
      route: null,
      name: null,
      title: '离职管理',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/office',
      inNav: true,
      // permission: ['离职审批'],
      children: [
        {
          title: '离职申请',
          type: 'view',
          name: null,
          route: '/resignation-application/list',
          filePath: 'view/office/resignation/resignation-application-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
        },
        {
          title: '离职审批',
          type: 'view',
          name: null,
          route: '/resignation-approval/list',
          filePath: 'view/office/resignation/resignation-approval-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['离职审批'],
        },
      ]
    },
    {
      route: null,
      name: null,
      title: '档案借查阅',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/office',
      inNav: true,
      children:[
        {
          title: '申请列表',
          type: 'view',
          name: null,
          route: '/files-trawling/list',
          filePath: 'view/office/files-trawling/files-trawling-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
        },
        {
          title: '审批列表',
          type: 'view',
          name: null,
          route: '/files-trawling-approval/list',
          filePath: 'view/office/files-trawling/files-trawling-approval-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
        },
      ]
    },
    {
      title: '公告管理',
      type: 'view',
      name: null,
      route: '/notice/list',
      filePath: 'view/office/notice/notice-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
    },
    {
      title: '通讯录',
      type: 'view',
      name: null,
      route: '/phone/list',
      filePath: 'view/office/phone-book.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['通讯录']
    },
  ],
}

export default officeRouter
