const dining = {
  route: null,
  name: null,
  title: '报餐管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/dining', // 文件路径
  order: null,
  inNav: true,
  permission:['报餐记录','报餐规则','报餐用户','报餐计划','补报餐','补报餐审批','新增报餐计划','报餐'],
  children: [
    {
      title: '报餐规则',
      type: 'view',
      name: null,
      route: '/dining/list',
      filePath: 'view/dining/rule/dining-rule.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission:['报餐规则']
    },
    {
      title: '报餐计划',
      type: 'view',
      name: null,
      route: '/dining/plan',
      filePath: 'view/dining/plan/dining-plan.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission:['报餐计划']
    },
    {
      title: '补餐申请列表',
      type: 'view',
      name: null,
      route: '/dining/application',
      filePath: 'view/dining/application/application-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission:['补报餐']
    },
    {
      title: '补餐审批列表',
      type: 'view',
      name: null,
      route: '/dining/applicationApproval',
      filePath: 'view/dining/application/approval-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission:['补报餐审批']
    },
    {
      title: '报餐用户',
      type: 'view',
      name: null,
      route: '/dining/user',
      filePath: 'view/dining/user/dining-user.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission:['报餐用户']
    },
    {
      title: '报餐记录',
      type: 'view',
      name: null,
      route: '/dining/info',
      filePath: 'view/dining/log/dining-info.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission:['报餐记录']
    },
  ],
}

export default dining
