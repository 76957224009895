const examRouter = {
  route: null,
  name: null,
  title: '考试管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/office', // 文件路径
  order: null,
  inNav: true,
  permission: ['考试预约','预约查询','成绩录入','考试预约配置','考试预约统计报表'],
  children: [
    {
      route: null,
      name: null,
      title: '预约管理',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/office',
      inNav: true,
      children: [
        {
          title: '考试预约',
          type: 'view',
          name: null,
          route: '/student-appointment-add/list',
          filePath: 'view/exam/student-appointment/student-appointment-add.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['考试预约'],
        },
        {
          title: '预约查询',
          type: 'view',
          name: null,
          route: '/student-appointment/list',
          filePath: 'view/exam/student-appointment/student-appointment-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['预约查询'],
        },
      ]
    },
    {
      route: null,
      name: null,
      title: '成绩管理',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/office',
      inNav: true,
      children: [
        {
          title: '成绩录入',
          type: 'view',
          name: null,
          route: '/student-achievement-add/list',
          filePath: 'view/exam/student-achievement/student-achievement-add.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['成绩录入'],
        },
        {
          title: '成绩查询',
          type: 'view',
          name: null,
          route: '/student-achievement/list',
          filePath: 'view/exam/student-achievement/student-achievement-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['预约查询'],
        },
      ]
    },
    {
      route: null,
      name: null,
      title: '统计报表',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/office',
      inNav: true,
      children: [
        {
          title: '服务费设置',
          type: 'view',
          name: null,
          route: '/exam/configure-info',
          filePath: 'view/exam/report/configure/configure-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['考试预约配置'],
        },
        {
          title: '报表信息',
          type: 'view',
          name: null,
          route: '/exam/report',
          filePath: 'view/exam/report/report.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['考试预约统计报表'],
        },
      ]
    },
  ],
}

export default examRouter
