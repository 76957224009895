const expenseRouter = {
  route: null,
  name: null,
  title: '缴费管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/office', // 文件路径
  order: null,
  inNav: true,
  permission: ['其他缴费','其他缴费查询','其他缴费标准','缴费标准','学员登记','学员查询','培训缴费',
  '培训缴费查询','缴费退办'],
  children: [
    {
      route: null,
      name: null,
      title: '高级功能',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/office',
      inNav: true,
      permission: ['其他缴费标准','缴费标准','缴费退办','培训缴费编辑','其他缴费编辑','学员编辑','过往学员'],
      children: [
        // {
        //   title: '数据修复',
        //   type: 'view',
        //   name: null,
        //   route: '/payment-standard/update',
        //   filePath: 'view/expense/payment-standard/update-list.vue',
        //   inNav: true,
        //   icon: 'iconfont icon-tushuguanli',
        //   permission: ['缴费标准'],
        // },
        {
          title: '缴费标准',
          type: 'view',
          name: null,
          route: '/payment-standard/list',
          filePath: 'view/expense/payment-standard/payment-standard-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['缴费标准'],
        },
        {
          title: '其他标准',
          type: 'view',
          name: null,
          route: '/other-payment-standard/list',
          filePath: 'view/expense/other-payment-standard/other-payment-standard-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['其他缴费标准'],
        },
        {
          title: '缴费退办',
          type: 'view',
          name: null,
          route: '/student-payment-cancel/list',
          filePath: 'view/expense/student-payment/student-payment-cancel-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['缴费退办'],
        },
        {
          title: '缴费编辑',
          type: 'view',
          name: null,
          route: '/student-payment/edit',
          filePath: 'view/expense/student-payment/student-payment-edit.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['培训缴费编辑'],
        },
        {
          title: '其他编辑',
          type: 'view',
          name: null,
          route: '/other-types-payment/edit',
          filePath: 'view/expense/other-types-payment/other-types-payment-edit.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['其他缴费编辑'],
        },
        {
          title: '学员编辑',
          type: 'view',
          name: null,
          route: '/student-info/edit',
          filePath: 'view/expense/student/student-edit.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['学员编辑'],
        },
        {
          title: '过往学员',
          type: 'view',
          name: null,
          route: '/old-student-query',
          filePath: 'view/expense/old-student-query/old-student-query.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['过往学员'],
        },
      ]
    },
    {
      route: null,
      name: null,
      title: '日常业务',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/office',
      inNav: true,
      permission: ['其他缴费','其他缴费查询','学员登记','学员查询','培训缴费','培训缴费查询','学员缴费操作日志'],
      children: [
        {
          title: '新增缴费',
          type: 'view',
          name: null,
          route: '/student-payment-add/list',
          filePath: 'view/expense/student-payment/student-payment-info.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['培训缴费'],
        },
        {
          title: '缴费查询',
          type: 'view',
          name: null,
          route: '/student-payment/list',
          filePath: 'view/expense/student-payment/student-payment-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['培训缴费查询'],
        },

        {
          title: '其他缴费',
          type: 'view',
          name: null,
          route: '/other-types-payment-add/list',
          filePath: 'view/expense/other-types-payment/other-types-payment-info.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['其他缴费'],
        },
        {
          title: '其他查询',
          type: 'view',
          name: null,
          route: '/other-types-payment/list',
          filePath: 'view/expense/other-types-payment/other-types-payment-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['其他缴费查询'],
        },

        {
          title: '学员登记',
          type: 'view',
          name: null,
          route: '/student-add/list',
          filePath: 'view/expense/student/student-info.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['学员登记'],
        },
        {
          title: '学员查询',
          type: 'view',
          name: null,
          route: '/student-info/list',
          filePath: 'view/expense/student/student-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['学员查询'],
        },
        {
          title: '在线缴费',
          type: 'view',
          name: null,
          route: '/student-online/list',
          filePath: 'view/expense/student-online-paymnet/stu-online-payment-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['培训缴费'],
        },
        {
          title: '操作日志',
          type: 'view',
          name: null,
          route: '/operation-log/list',
          filePath: 'view/expense/operation-log/operation-log-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['学员缴费操作日志'],
        },
      ]
    },
    {
      route: null,
      name: null,
      title: '优惠管理',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/office',
      inNav: true,
      children: [
        {
          title: '优惠券',
          type: 'view',
          name: null,
          route: '/discount-coupon/list',
          filePath: 'view/expense/discount/discount-coupon-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
        },
        {
          title: '优惠订单',
          type: 'view',
          name: null,
          route: '/discount-order/list',
          filePath: 'view/expense/discount/discount-order-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
        },
      ]
    },
    // {
    //   title: '导入',
    //   type: 'view',
    //   name: null,
    //   route: '/student-payment-inport/list',
    //   filePath: 'view/expense/student-payment/import/index.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-tushuguanli',
    // },
  ],
}

export default expenseRouter
