const evaluate = {
  route: null,
  name: null,
  title: '评价管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/evaluate/', // 文件路径
  order: null,
  inNav: true,
  permission:['员工评价列表','员工评价详情','考核列表','考核信息','考核设置'],
  children: [
    {
      route: null,
      name: null,
      title: '员工考核',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/evaluate',
      inNav: true,
      permission:['考核列表','考核信息','考核设置'],
      children:[
        {
          title: '考核项目',
          type: 'view',
          name: 'evaluateType',
          route: '/evaluate/evaluate-type',
          filePath: 'view/evaluate/evaluate-type.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission:['考核设置']
        },
        {
          title: '考核题目',
          type: 'view',
          name: 'evaluateTitle',
          route: '/evaluate/evaluate-title/list',
          filePath: 'view/evaluate/evaluate-title-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission:['考核设置']
        },
        {
          title: '题目模板',
          type: 'view',
          name: 'evaluateTitles',
          route: '/evaluate/evaluate-titles/list',
          filePath: 'view/evaluate/evaluate-titles-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission:['考核设置']
        },
        {
          title: '考核信息',
          type: 'view',
          name: 'evaluateExamine',
          route: '/evaluate/evaluate-examine/list',
          filePath: 'view/evaluate/evaluate-examine-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission:['考核信息']
        },
        {
          title: '考核列表',
          type: 'view',
          name: 'evaluateAssessor',
          route: '/evaluate/evaluate-assessor/list',
          filePath: 'view/evaluate/evaluate-assessor-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission:['考核列表']
        },
      ]
    },
    {
      route: null,
      name: null,
      title: '外来人员评价',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/evaluate',
      inNav: true,
      permission:['员工评价列表','员工评价详情'],
      children: [
        {
          title: '评价列表',
          type: 'view',
          name: 'employee-evaluate',
          route: '/evaluate/outside/employee-evaluate/list',
          filePath: 'view/evaluate/outside/employee-evaluate-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission:['员工评价列表']
        },
        {
          title: '评价详情',
          type: 'view',
          name: 'employee-outside',
          route: '/evaluate/outside/employee-outside/list',
          filePath: 'view/evaluate/outside/employee-outside-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission:['员工评价详情']
        }
      ]
    },
  ],
}

export default evaluate
