const warehouseRouter = {
  route: null,
  name: null,
  title: '仓库管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/warehouse', // 文件路径
  order: null,
  inNav: true,
  permission: ['仓库报表','固资类别','固资信息','固资盘点','物品类别','物品信息','物品盘点','物品入库','物品领用','仓库信息'],
  children: [
    {
      title: '仓库信息',
      type: 'view',
      name: null,
      route: '/warehouse/list',
      filePath: 'view/warehouse/warehouse-info/warehouse-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['仓库信息'],
    },
    {
      title: '物品类别',
      type: 'view',
      name: null,
      route: '/item-category/list',
      filePath: 'view/warehouse/item-category/item-category-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['物品类别'],
    },
    {
      title: '物品信息',
      type: 'view',
      name: null,
      route: '/item-info/list',
      filePath: 'view/warehouse/item-info/item-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['物品信息'],
    },
    {
      title: '物品入库',
      type: 'view',
      name: null,
      route: '/items-purchase/list',
      filePath: 'view/warehouse/items-purchase/items-purchase-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['物品入库'],
    },
    {
      title: '物品入库详情',
      type: 'view',
      name: null,
      route: '/items-purchase/details',
      filePath: 'view/warehouse/items-purchase/items-purchase-details.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['物品入库'],
    },
    {
      title: '物品领用',
      type: 'view',
      name: null,
      route: '/items-requisition/list',
      filePath: 'view/warehouse/items-requisition/items-requisition-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['物品领用'],
    },
    {
      title: '物品领用详情',
      type: 'view',
      name: null,
      route: '/items-requisition/details',
      filePath: 'view/warehouse/items-requisition/items-requisition-details.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['物品领用'],
    },
    {
      title: '物品盘点',
      type: 'view',
      name: null,
      route: '/items-check/list',
      filePath: 'view/warehouse/items-check/items-check-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['物品盘点'],
    },
    {
      title: '固资类别',
      type: 'view',
      name: null,
      route: '/fixed-assets-category/list',
      filePath: 'view/warehouse/fixed-assets-category/fixed-assets-category-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['固资类别'],
    },
    {
      title: '固资信息',
      type: 'view',
      name: null,
      route: '/fixed-assets/list',
      filePath: 'view/warehouse/fixed-assets/fixed-assets-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['固资信息'],
    },
    {
      title: '固资盘点',
      type: 'view',
      name: null,
      route: '/fixed-assets-check/list',
      filePath: 'view/warehouse/fixed-assets-check/fixed-assets-check-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['固资盘点'],
    },
    /*{
      title: '导入',
      type: 'view',
      name: null,
      route: '/warehouse-import/list',
      filePath: 'view/warehouse/import/index.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
    },*/
    {
      title: '仓库报表',
      type: 'view',
      name: null,
      route: '/warehouse-report/list',
      filePath: 'view/warehouse/report/report.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['仓库报表'],
    },
  ],
}

export default warehouseRouter
