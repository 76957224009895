const analysisRouter = {
  route: '/student/analysis',
  name: 'analysis',
  title: '数据分析',
  type: 'view',
  icon: 'iconfont icon-kecheng',
  filePath: 'view/expense/analysis/student-analysis',
  order: 1,
  inNav: true,
  permission: ['超级管理员独有权限'],

}

export default analysisRouter
