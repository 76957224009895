const vehicleRouter = {
  route: null,
  name: null,
  title: '车辆管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/office', // 文件路径
  order: null,
  inNav: true,
  permission: ['考试车辆列表','用车申请','部门经理用车申请审批','副总经理用车申请审批','车辆列表',
    '维修保养','部门经理维修保养审批','分管领导维修保养审批','用车加油','自培车辆列表','其他类车辆列表'],
  children: [
    {
      title: '业务车辆列表',
      type: 'view',
      name: null,
      route: '/vehicle/list',
      filePath: 'view/office/vehicle/vehicle-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['车辆列表'],
    },
    {
      title: '考试车辆列表',
      type: 'view',
      name: null,
      route: '/test-vehicle/list',
      filePath: 'view/office/test-vehicle/test-vehicle-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['考试车辆列表'],
    },
    {
      title: '自培车辆列表',
      type: 'view',
      name: null,
      route: '/self-training-vehicle/list',
      filePath: 'view/office/vehicle/self-training-vehicle/self-training-vehicle-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['自培车辆列表'],
    },
    {
      title: '其他类车辆',
      type: 'view',
      name: null,
      route: '/other-vehicle/list',
      filePath: 'view/office/vehicle/other-vehicle/other-vehicle-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['其他类车辆列表'],
    },
    {
      route: null,
      name: null,
      title: '维修保养管理',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/office',
      inNav: true,
      permission: ['维修保养','维修保养审批'],
      children: [
        {
          title: '维修保养',
          type: 'view',
          name: null,
          route: '/vehicle-maintenance/list',
          filePath: 'view/office/vehicle-maintenance/vehicle-maintenance-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['维修保养'],
        },
        {
          title: '待审批',
          type: 'view',
          name: null,
          route: '/vehicle-maintenance-approval/list',
          filePath: 'view/office/vehicle-maintenance/vehicle-maintenance-approval-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['维修保养审批'],
        },
      ],
    },
    {
      title: '用车加油信息',
      type: 'view',
      name: null,
      route: '/vehicle-refuel/list',
      filePath: 'view/office/vehicle-refuel/vehicle-refuel-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['用车加油'],
    },
    {
      title: '油卡充值记录',
      type: 'view',
      name: null,
      route: '/vehicle-recharge/list',
      filePath: 'view/office/vehicle-recharge/vehicle-recharge-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['油卡充值'],
    },
    {
      route: null,
      name: null,
      title: '其他油卡管理',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/office',
      inNav: true,
      children: [
        {
          title: '主卡信息',
          type: 'view',
          name: null,
          route: '/vehicle-Oilcard/list',
          filePath: 'view/office/vehicle-oilcard/vehicle-oilcard-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
        },
        {
          title: '副卡信息',
          type: 'view',
          name: null,
          route: '/vehicle-Oilcard/info',
          filePath: 'view/office/vehicle-oilcard/vehicle-oilcard-info.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
        }
      ]
    },
    {
      route: null,
      name: null,
      title: '用车申请管理',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/office',
      inNav: true,
      permission: ['用车申请','用车申请审批'],
      children: [
        {
          title: '用车申请',
          type: 'view',
          name: null,
          route: '/vehicle-application/list',
          filePath: 'view/office/vehicle-application/vehicle-application-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['用车申请'],
        },
        {
          title: '待审批',
          type: 'view',
          name: null,
          route: '/vehicle-application-approval/list',
          filePath: 'view/office/vehicle-application/vehicle-application-approval-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['用车申请审批'],
        },
        {
          title: '行政待审',
          type: 'view',
          name: null,
          route: '/vehicle-administration-approval/list',
          filePath: 'view/office/vehicle-application/vehicle-administration-approval-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['行政待审'],
        },
      ],
    },
    {
      title: '到期提醒设置',
      type: 'view',
      name: null,
      route: '/vehicel-warn/list',
      filePath: 'view/office/vehicle/vehicle-warn-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
    },
    /*{
      title: '导入',
      type: 'view',
      name: null,
      route: '/office-import/list',
      filePath: 'view/office/import/index.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
    },*/
  ],
}

export default vehicleRouter
