const attendanceRouter = {
  route: null,
  name: null,
  title: '考勤管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/attendance', // 文件路径
  order: null,
  inNav: true,
  permission: ['考勤规则', '考勤记录','考勤补卡','考勤记录修改','请假申请','请假审批','请假统计','加班申请','加班审批','加班统计','年假规则'],
  children: [
    {
      title: '考勤规则',
      type: 'view',
      name: null,
      route: '/attendance/list',
      filePath: 'view/attendance/attendance-info.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['考勤规则'],
    },
    {
      title: '考勤记录',
      type: 'view',
      name: null,
      route: '/attendance/log',
      filePath: 'view/attendance/attendance-log.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['考勤记录'],
    },
    {
      route: null,
      name: null,
      title: '请假管理',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/attendance',
      inNav: true,
      permission: ['请假申请','请假审批','请假统计'],
      children: [
        {
          title: '请假申请',
          type: 'view',
          name: null,
          route: '/leaveList',
          filePath: 'view/attendance/leave/leave-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['请假申请'],
        },
        {
          title: '请假审批',
          type: 'view',
          name: null,
          route: '/leaveApproveList',
          filePath: 'view/attendance/leave/leave-approve-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['请假审批'],
        },
        {
          title: '请假统计',
          type: 'view',
          name: null,
          route: '/leaveAllList',
          filePath: 'view/attendance/leave/leave-log.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['请假统计'],
        },
      ]
    },
    {
      route: null,
      name: null,
      title: '加班管理',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/attendance',
      inNav: true,
      permission: ['加班申请','加班审批','加班统计'],
      children:[
        {
          title: '加班申请',
          type: 'view',
          name: null,
          route: '/workList',
          filePath: 'view/attendance/work/work-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['加班申请'],
        },
        {
          title: '加班审批',
          type: 'view',
          name: null,
          route: '/workApproveList',
          filePath: 'view/attendance/work/work-approve-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['加班审批'],
        },
        {
          title: '加班统计',
          type: 'view',
          name: null,
          route: '/workAllList',
          filePath: 'view/attendance/work/work-log.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['加班统计'],
        },
      ]
    },
    {
      title: '年假规则',
      type: 'view',
      name: null,
      route: '/holodayinfo',
      filePath: 'view/attendance/holiday/yearholiday-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['年假规则'],
    },
  ],
}

export default attendanceRouter
