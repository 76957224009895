const insideRouter = {
  route: null,
  name: null,
  title: '内部资源',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/inside', // 文件路径
  order: null,
  inNav: true,
  permission: ['分公司','分教场','教练管理','教练车辆','招生点'],
  children: [
    {
      title: '教练管理',
      type: 'view',
      name: null,
      route: '/coach/list',
      filePath: 'view/inside/coach/coach-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['教练管理'],
    },
    {
      route: null,
      name: null,
      title: '教练员申请',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/inside/application',
      inNav: true,
      permission: ['教练申请列表','教练审核列表'],
      children: [
        {
          title: '申请列表',
          type: 'view',
          name: null,
          route: '/coach-application/list',
          filePath: 'view/inside/application/coach-application-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['教练申请列表']
        },
        {
          title: '审核列表',
          type: 'view',
          name: null,
          route: '/coach-approve/list',
          filePath: 'view/inside/application/coach-approve-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['教练审核列表']
        },
        {
          title: '行政审核',
          type: 'view',
          name: null,
          route: '/coach-administration/list',
          filePath: 'view/inside/application/coach-administration-approve-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          // permission: ['教练行政审核']
        },
      ]
    },
    {
      title: '教练车辆',
      type: 'view',
      name: null,
      route: '/coach-vehicle/list',
      filePath: 'view/inside/coach-vehicle/coach-vehicle-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['教练车辆'],
    },
    {
      route: null,
      name: null,
      title: '分公司管理',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/office',
      inNav: true,
      permission: ['分公司','分教场','招生点'],
      children: [
        {
          title: '分公司',
          type: 'view',
          name: null,
          route: '/branch-office/list',
          filePath: 'view/inside/branch-office/branch-office-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['分公司'],
        },
        {
          title: '招生点',
          type: 'view',
          name: null,
          route: '/registration-point/list',
          filePath: 'view/inside/registration-point/registration-point-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['招生点'],
        },
        {
          title: '分教场',
          type: 'view',
          name: null,
          route: '/branch-teaching-place/list',
          filePath: 'view/inside/branch-teaching-place/branch-teaching-place-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
          permission: ['分教场'],
        },

      ]
    },
    {
      route: null,
      name: null,
      title: '教练费用',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/office',
      inNav: true,
      children: [
        {
          title: '费用类别',
          type: 'view',
          name: null,
          route: '/coach-social-security/category',
          filePath: 'view/inside/social_security/coach-social-security-category.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
        },
        {
          title: '费用添加',
          type: 'view',
          name: null,
          route: '/coach-social-security/add',
          filePath: 'view/inside/social_security/coach-social-security-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
        },
        {
          title: '费用报表',
          type: 'view',
          name: null,
          route: '/coach-social-security/report',
          filePath: 'view/inside/social_security/report/report.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
        },
      ]
    },
    /*{
      title: '导入',
      type: 'view',
      name: null,
      route: '/inside-import/list',
      filePath: 'view/inside/import/index.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
    },*/

  ],
}

export default insideRouter
