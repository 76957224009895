const financeRouter = {
  route: null,
  name: null,
  title: '财务管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/finance', // 文件路径
  order: null,
  inNav: true,
  permission: ['报表信息', '员工工资'],
  children: [
    {
      route: null,
      name: null,
      title: '员工工资',
      type: 'folder', // 取 route 为默认加载页
      icon: 'iconfont icon-tushuguanli',
      filePath: 'view/employee-salary',
      inNav: true,
      permission: ['员工工资'],
      children: [
        {
          title: '工资类别',
          type: 'view',
          name: null,
          route: '/employee/salary-category',
          filePath: 'view/office/employee-salary/employee-salary-category.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
        },
        {
          title: '员工工资',
          type: 'view',
          name: null,
          route: '/employee/salary-list',
          filePath: 'view/office/employee-salary/employee-salary-list.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
        },
        {
          title: '工资报表',
          type: 'view',
          name: null,
          route: '/employee/salary-report',
          filePath: 'view/office/employee-salary/report/report.vue',
          inNav: true,
          icon: 'iconfont icon-tushuguanli',
        },
      ],
    },
    {
      title: '报表信息',
      type: 'view',
      name: null,
      route: '/finance-report/list',
      filePath: 'view/finance/report.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['报表信息'],
    },
  ],
}

export default financeRouter
